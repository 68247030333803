import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import useSyncSetting from "../Orders/useSyncSetting";
import './MenuUpdater.scss'
import {Alignment, Switch} from "@blueprintjs/core"
export default function MenuUpdater(props) {
    const r_id = props.match.params.r_id
    const [searchTerm, setSearchTerm] = useState('')
    const [menu, setMenu] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [menuRef, menuValue] = useSyncSetting(r_id, 'menu', false, {})
    const [search, setSearch] = useState('')

    const menuLink = `https://afoodapart.com/api/menu/${r_id}`

    let searchResult = []
    if (search.length > 2) {
        menu.forEach((category) => {
            category.products.forEach((item) => {
                if (item.name.en.toLowerCase().includes(search.toLowerCase())) {
                    searchResult.push(item)
                }
            })
        }
        )
    }
    
    // fetch menu
    useEffect(() => {
        fetch(menuLink)
        .then(response => response.json())
        .then(data => {
            console.log(data.menu)
            setMenu(data.menu.subMenus)
        })
    }, [menuLink])

    if (menu.length === 0) {
        return <div>Loading Menu...</div>
    }

    const currentlyOutOfStockItems = []


    return (
        <div className="menu-updater">
        <div className="navigation">
            <Link to={`/r/${r_id}`}>Orders</Link>  <div className="path-separator">/</div>   <h1>Menu Updater for {r_id}</h1>
        </div>

        <div className="search">
            <input type="text" placeholder="Search for items" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className="search-result">
            <h2  className="section-heading">Search Result </h2>
            {searchResult.map((item, index) => {
                return (
                    <MenuItemQuickEditRow item={item} key={item.id} menuValue={menuValue} menuRef={menuRef}  />
                )
            })}
        </div>
       

       <div className="out-of-stock-items">
        <h2 className="section-heading">Currently Out of Stock Items</h2>
        <ul>
        {menu.map((category, index) => {
            return (
                <div key={category.id}>
                {/* <h2>{category.name.en}</h2> */}
                <ul>
                {category.products.map((item, index) => {
                    if (menuValue[item.id] && menuValue[item.id].outOfStock) {
                        return (
                            <MenuItemQuickEditRow item={item} key={item.id} menuValue={menuValue} menuRef={menuRef}  />
                        )
                    }
                })}
                </ul>
                </div>
            )
        })}
        </ul>
       </div>

       

       {!selectedCategory ? <div className="categories">
        <h2>Categories</h2>
            <ul className="category-button-list">
            {menu.map((category, index) => {
                return (
                    <li className="category-button" key={category.id}>
                        <a href='#' className="category-button-link" onClick={() => {setSelectedCategory(category.id)}}>
                            <div className="category-button-text">{category.name.en}</div></a>
                    </li>
                )
            })}
            </ul>
       </div> : null}

        <div>
        {selectedCategory ? menu.map((category, index) => {
            if (selectedCategory && category.id !== selectedCategory) {
                return null
            }

            return (
                <div key={category.id}>
                <div className="navigation">
                    <a href="#" onClick={() => setSelectedCategory(null)}>ALL Categories</a>  <div className="path-separator">/</div>   <h1>{category.name.en}</h1>
                </div>
                <ul>
                {category.products.map((item, index) => {
                    return (
                        <MenuItemQuickEditRow item={item} key={`${item.id}`} menuValue={menuValue} menuRef={menuRef}  />
                    )
                })}
                </ul>
                </div>
            )
        }) : null}
        
            
        </div>
        </div>
    )
}


function MenuItemQuickEditRow ({item, menuValue, menuRef}) {
    const isOutOfStock = menuValue && menuValue[item.id] && menuValue[item.id].outOfStock
    const inStock = !isOutOfStock
    function onToggleInOutSwitch (event) {
        console.log('checkedState', event.target.checked)
        if (event.target.checked) {
            // if change to checked, that means it is availalbe
             menuRef.child(item.id).child('outOfStock').remove()
        } else {
            // if change to unchecked, that means it is out of stock
            menuRef.child(item.id).child('outOfStock').set("OUT_OF_STOCK")
        }

        // if (checkedState) {
        //     // delete 'outOfStock'
        //     menuRef.child(item.id).child('outOfStock').remove()
        // } else {
        // }
    }
    return (
        <li className="menu-item">
        <Switch
            className="online-switch widget"
            large={true}
            innerLabel={"Out Of Stock"}
            innerLabelChecked={"Available"}
            checked={inStock}
            onChange={onToggleInOutSwitch}
            label=""
            inline
            />
        <h3>{item.name.en}</h3>
        {/* <p>{item.description}</p> */}
        {/* <p>${item.price}</p> */}
        </li>
    )
}