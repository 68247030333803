import React, { Component, Suspense, lazy } from 'react';
import {Link, Route, Switch} from 'react-router-dom'

import './App.scss';
import CurrentOrders from './CurrentOrders'
import Orders from './Orders'
// import OrdersTwo from './OrdersTwo'
import Settings from './Settings'
import OrderDetails from './Numbers/OrderDetails'
import Signin from './Auth'
import MenuUpdater from './MenuUpdater';

const Logs = lazy(() => import('./Stats/Logs'))
const Tax = lazy(() => import('./Settings/Tax'))
const Notify = lazy(() => import('./Notify'))
const Ninja = lazy(() => import('./Notify/Ninja'))
const Numbers = lazy(() => import('./Numbers'))
const NumbersTwo = lazy(() => import('./Numbers/index2'))
const Admin = lazy(() => import('./Admin'))
const AllReports = lazy(() => import('./Numbers/All'))
const AllRefunds = lazy(() => import('./Numbers/AllRefundTasks'))
const AllUnaccepted = lazy(() => import('./Numbers/AllUnaccepted'))
const Devices = lazy(() => import('./Admin/Devices'))
const YesWeAreOpen = lazy(() => import('./Admin/YesWeAreOpen'))
const Secrets = lazy(() => import('./Settings/secrets'))
const AllOrders = lazy(() => import('./Notify/AllOrders'))
const Bridge = lazy(() => import('./Settings/Bridge'))

class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <header className="App-header">
          <nav>
            <ul>
              <li><Link to="">History</Link></li>
              <li><Link to="">Current</Link></li>
              <li><Link to="">Completed</Link></li>
            </ul>
          </nav>
        </header> */}
        <Suspense fallback={<div>Loading</div>}>
          <Switch>

            <Route path="/devices" exact component={Devices}></Route>
            <Route path="/open" exact component={YesWeAreOpen}></Route>
            <Route path="/signin" exact component={Signin}></Route>
            <Route path="/admin" component={Admin}></Route>
            <Route path="/dispatch" exact component={AllOrders}></Route>
            <Route path="/dispatch/:shopId/:orderId" exact component={AllOrders}></Route>
            <Route path="/notify" exact component={Notify}></Route>
            <Route path="/ninja" exact component={Ninja}></Route>
            <Route path="/all-reports/:begin?/:end?" exact render={({match: {params: {begin, end}}}) => {
              return (<AllReports begin={begin} end={end} />)
            }} />
            <Route path="/all-refunds/:begin?/:end?" exact render={({match: {params: {begin, end}}}) => {
              return (<AllRefunds begin={begin} end={end} />)
            }} />
            <Route path="/all-un/:begin?/:end?" exact render={({match: {params: {begin, end}}}) => {
              return (<AllUnaccepted begin={begin} end={end} />)
            }} />

            <Route path="/r/:r_id/settings" component={Settings}></Route>
            <Route path="/r/:r_id/promos" component={Bridge}></Route>
            <Route path="/r/:r_id/secrets" component={Secrets}></Route>
            <Route path="/r/:r_id/numbers/:begin?/:end?" render={({match: {params: {r_id, begin, end}}}) => {
              return (<div>unauthorized access</div>)
            }} />
            <Route path="/r/:r_id/numbers2/:begin?/:end?" render={({match: {params: {r_id, begin, end}}}) => {
              if (['pablitostacosburbank', 'pablitostacosnoho', 'mikaza'].includes(r_id)) {
                return (<div>unauthorized access</div>)

              }
              return (<Numbers r_id={r_id} begin={begin} end={end} />)
            }} />
            <Route path="/r/:r_id/reports/67df23e/:begin?/:end?" render={({match: {params: {r_id, begin, end}}}) => {
              return (<Numbers r_id={r_id} baseURL={"reports/67df23e"} begin={begin} end={end} />)
            }} />
            <Route path="/r/:r_id/logs" exact component={Logs}></Route>
            <Route path="/r/:r_id/legacy" exact component={CurrentOrders}></Route>
            <Route path="/r/:r_id/menu" component={MenuUpdater}></Route>
            <Route path="/r/:r_id" component={Orders}></Route>
            {/* <Route path="/r2/:r_id" component={OrdersTwo}></Route> */}

            <Route path="/details2/:r_id/:o_id" component={OrderDetails}></Route>
            <Route path="/tax/:secret/:r_id/:key" component={Tax}></Route>

            {/* <Route path="/r/:r_id/pos/:order_id" exact component={Orders}></Route> */}

            {/* <Route path="/stats" component={Stats}></Route> */}
          </Switch>
          {/* <Route path="/r/:r_id/:o_id/pdf" exact component={PDF}></Route> */}

        </Suspense>
      </div>
    );
  }
}

export default App;
