import firebase from '../firebase'
import {useState, useEffect, useRef, useReducer} from 'react'

export default function useSyncSetting(r_id, path, getOnce, defaultValue) {

  const settingRef = firebase.database().ref(`settings/${r_id}/${path}`)
  const [settingValue, setSettingValue] = useState(defaultValue)
  // console.log('usYS')
  // console.log()
  useEffect(function () {
    if (getOnce) {
      settingRef.once('value', function (snap) {
        console.log('downloading once', path)
        if (!snap.exists())  {return}
        setSettingValue(snap.val())
      })
    } else {
      console.log('syncing with', path)
      settingRef.on('value', function (snap) {
        console.log('syncronizing', path)
        // console.log(snap.val())
        // if (!snap.exists())  {return}
        setSettingValue(snap.val() || defaultValue)
      })

      return () => {
        settingRef.off()
      }
    }
    
  }, [r_id, path, getOnce])

  return([settingRef, settingValue])
}