import PhoneLink from '../OrderItem/PhoneLink'
import DriverRequest from '../DriverRequest'
import Fax from '../Fax'
import Payment from '../Payment'
import {format} from 'date-fns'
import {floor, groupBy, get} from 'lodash'
import ModgroupDisplay from '../ModgroupDisplay'
import makePhotoURL from '../utils/makePhotoURL'
import {Button, Checkbox, Card, Icon} from "@blueprintjs/core"
import localized from '../localized'
import moment from 'moment-timezone'
import {Link, withRouter} from 'react-router-dom'
import NotifyStatus from '../Notify/Status'
import OrderItemAdmin from './OrderItemAdmin'
import OrderItemsTodoList from './OrderItemsTodoList'
import OrderItemDueEditor from './OrderItemDueEditor'
import { organizeItems } from './organizedItems'

// import CancelOrderAction from './OrderItemActions/CancelOrderAction'
import RefundOrderAction from './OrderItemActions/RefundOrderAction'

import printOrder from './Print/printOrder'

import './orderItem.scss'

import React, { Component, useState } from 'react';
function OrderItem (props) {
    const [showOldOrder, setShowOldOrder] = useState(false)
    const snapshot = props.snapshot
    const settings = props.settings || {}
    console.log('settings', settings)
    const restaurantPhoneNumber = get(settings, 'restuarantPhone', '')
    if (!props.snapshot) {return null}
    if (!props.snapshot.exists()) {
      return <div>select an order from left.</div>
    }
    const order = props.snapshot.val()
    const id = props.snapshot.key
    let {
      isTest,
      c_at,
      r_id,
      dueTS,
      orderNumber,
      items,
      name,
      phone,
      cartType,
      tabNumber,
      deliveryTime,
      pickupTime,
      paymentType,
      deliveryAddress,
      deliveryInstructions,
      distanceInMiles,
      unitNumber,
      chargeResult,
      orderAcceptedByShop,
      orderComplete,
      tags={},
      isCash,
      tips,
      promoCode,
      toDoRefundInCents,
      utensilsOption,
      totals={}} = order
      const {numberOfMarketPriceItems, totalPrice, discount, invoice, deliveryFeeInCents, tax, allServiceFee, preTipTotalWithTax, agreeToDonate, restaurantDonationCents} = totals

      if(!items) {
        return null
      }
      const organizedItems = organizeItems(items)
      // console.log('DE',organizedItems)

      // const isTestOrder = (name && name.toLowerCase() === 'test')
      const testOrderClass = isTest ? ' test-order' : ''
      // console.log(JSON.stringify(order))

      let scheduledTS = ((cartType === 'DELIVERY') ? deliveryTime : pickupTime)
      if (cartType === 'DINEIN') {scheduledTS = false}

      const hoursBeforeSchedule = Number(scheduledTS) ? (scheduledTS - Date.now()) / 1000 / 60 / 60 : 0
      console.log(hoursBeforeSchedule)
      const hoursPassedOrderDue = (Date.now() - dueTS) / 1000 / 60 / 60
      const showWarning = (hoursBeforeSchedule > 1.5) ? true : false

      const scheduledOrderClass = scheduledTS ? " scheduled" : ""

      function forceShowOrderDetails () {
        setShowOldOrder(true)
      }


      function betaPrint () {
        printOrder({order, id})
      }

      let cartTypeText = (cartType === 'DELIVERY') ? "Delivery" : "Pick Up"
      if (cartType === 'DINEIN') { cartTypeText = 'Dine In' }
      if (Number(tabNumber)) { cartTypeText = `Dine In @ ${tabNumber}` }


      return(
        <div
          key={id}
          className={"foh-order" + testOrderClass + scheduledOrderClass}>

          {(process.env.NODE_ENV === 'development') &&
            <div className="order-actions pane admin">
              <OrderItemDueEditor snapshot={snapshot}></OrderItemDueEditor>
              <label htmlFor="has-alcohol">alcohol:</label>
              <input type="checkbox" id="has-alcohol"
                onChange={(e) => { snapshot.child('hasAlcohol').ref.set(e.target.checked) }}
                checked={snapshot.child('hasAlcohol').val()}/>

              <div>
                Ref: {order.referrer} QS: {(order.queryStrings || []).join(' ')}
              </div>
              <div>{moment(c_at).format(`M-D h:mmA`)}</div>
            </div>
          }
          {(process.env.NODE_ENV === 'development') &&
            <div className="analytics pane">
              <h3>Analytics (Admin View)</h3>
              <div>
                <span className="attr">referrer:</span> {order.referrer}
              </div>
              <div>
                <span className="attr">tracking:</span> {(order.queryStrings || []).join(' ')}
              </div>
              <div>
                <span className="attr">promo code:</span> {order.promoCode}
              </div>
            </div>
          }

          <div className="order-actions pane">
            {/* <CancelOrderAction snapshot={snapshot} /> */}
            {/* <Button onClick={() => snapshot.ref.update({orderAcceptedByShop: true})}

              intent={snapshot.child('orderAcceptedByShop').val() ? "success" : "none"}
              icon="tick">Accept</Button> */}
            <RefundOrderAction snapshot={snapshot} />
            {!orderComplete ? <Button

              // intent="danger"
              onClick={() => {
                try {
                  const shouldComplete = window.confirm("Hide Order?")
                  if (shouldComplete) {
                    snapshot.ref.update({orderComplete: true})
                    props.history.push(props.orderListURL)
                  }
                } catch (e) {
                  console.log(e)
                }
              }}
              >
              Hide Order
              </Button> :
              <Button

                // intent="warning"
                onClick={() => { snapshot.ref.update({orderComplete: false}) }} >
                  Unhide
              </Button>
            }


          </div>






          {orderNumber &&
          <div
            onClick={() => console.log(JSON.stringify(order))}
            className="order-type pane">
            <span className="order-number__number token"><sup>#</sup>{orderNumber}</span>
            <span className="cart-type token">{cartTypeText}</span>
            {(paymentType === 'CARD') ?
              <span className="prepaid-label token">Pre-Paid (do not charge)</span> :
              <span className="prepaid-label token">Not Paid</span>
            }
            <Schedule ts={scheduledTS} c_at={c_at} cartType={cartType} r_id={r_id} />
          </div>}

          {/* Order Payment and Rewards Info, credit card,  */}

          <div className="customer-info pane">
            <div className="basic-info">
              {tips && <span className="tip">tip: ${tips / 100}</span>}
              <Icon icon="person" iconSize={25}/>{name}
              {/* {tabNumber ? " tab_" + tabNumber : '' } */}
              <Icon icon="phone"  iconSize={25}/><PhoneLink phone={phone} />
            </div>

            {(cartType==='DELIVERY') &&
            <div className="delivery-info">
              <div className="attr-name">Distance: </div><div>{distanceInMiles}</div>
              <div className="attr-name">Address: </div><div>{deliveryAddress}</div>
              <div className="attr-name">Apt/Unit: </div><div>{unitNumber}</div>
              <div className="attr-name">Note: </div><div>{deliveryInstructions}</div>

            </div>
          }
          </div>


          {((hoursPassedOrderDue > 3) && !showOldOrder) ? null :
            <OrderItemsTodoList
              settings={settings}
              hoursBeforeSchedule={hoursBeforeSchedule}
              snapshot={snapshot} />}

          {/* <div className="pane">
            Tip: {tips}
          </div> */}

          {window.isApp && <div
            className="app-controls pane">
            <Button className="print-button" large intent={"primary"} onClick={betaPrint}>Print</Button>
          </div>}

          {((hoursPassedOrderDue > 3) && !showOldOrder) ?
            <div className="show-older-items-action">
              <div className="text">Order Details Are Hidden</div>
              <div className="text">This order was due 3+ hours ago</div>
              <div className="text"><Button onClick={forceShowOrderDetails}>Show Order Details</Button></div>
            </div>
            : <ol className="order-items-list pane">




            {
              organizedItems.map(({products:items, printerTagDisplay, numberOfitems, mergedProducts}) => {
                return(
                  <div className="items-group">
                    <div className="items-group-name">
                      <span className="items-group-name-text">
                         {printerTagDisplay} ({numberOfitems} items)
                        </span>
                    </div>
                    <div className="group-items">
                    {items.map((item) => {
                      // id is timestamp
                      let {id, sub, name, totalPrice, pickedModgroups,
                        note, category, photo, quantity=1} = item

                      const imgURL = makePhotoURL(photo, {width: 100})

                      const quantityCss = (Number(quantity) === 1) ? "quantity single-quantity" : "quantity"

                      // let category = item["Top Level"]
                      return(
                        <li className="order-item" key={id}>
                          {/* <figure className="thumbnail">
                            {(imgURL !== 'no-pic') && <img src={imgURL} alt=""/>}
                          </figure> */}

                          <div className={quantityCss}><span className="text">{quantity || 1} ×</span></div>

                          <div className="text">
                            <Schedule type="product" ts={scheduledTS} cartType={cartType} />
                            {/* {sub && <div className="category">{sub}</div>} */}
                            <div className="order-item-details">
                              <span className="name">
                                {localized(name)} 
                                {(['test', 'frontierwok'].includes(r_id)) ? 
                                <span className="alt-language">
                                  
                                  {get(name, 'kr', '')}
                                </span>
                                : null}
                              </span>
                              <span className="price">${floor(totalPrice, 2)}</span>
                            </div>

                            <ModgroupDisplay pickedModgroups={pickedModgroups} />

                            {note ? <div className="modgroup">
                              <div className="group-name">
                                customer note
                              </div>
                              <div className="note">{note}</div>
                            </div> : null}
                          </div>
                        </li>
                      )
                      })}

                    </div>
                  </div>
                )
              })
            }
            
            {
              settings.enableUtensilsPicker && 
              <li className="utensils-option">
                Utensils: {(utensilsOption !== 'NO_UTENSILS') ? "YES" : "NO"}
              </li>
            }

          </ol>}

         
          <div className="pane">
            {totalPrice && <div className="order-totals">
              <span className="label">Subtotal:</span>
              <div className="amount">${totalPrice}</div>
           
              {(cartType === 'DELIVERY') && <span className="label">Delivery Fee:</span>}
              {(cartType === 'DELIVERY') && <div className="amount">${(deliveryFeeInCents / 100) || 0}</div>}
              {(discount > 0) && <span className="label">Discounts:</span>}
              {(discount > 0) && <div className="amount">${discount || 0}</div>}
              <span className="label">Taxes:</span>
              <div className="amount">{tax ? `$${tax}` : "*"}</div>
              {allServiceFee ? <span className="label">Service Fee:</span> : null}
              {allServiceFee ? <div className="amount">${allServiceFee}</div> : null}
              {(agreeToDonate) && <span className="label">Donation:</span>}
              {(agreeToDonate) && <div className="amount">${restaurantDonationCents / 100}</div>}
              <div className="divider"></div>
              
              <span className="label grand-total">Grand Total:</span>
              <div className="amount grand-total">${preTipTotalWithTax || invoice}</div>
              {tips && <span className="label tips">Tips:</span>}
              {tips && <div className="amount">${tips / 100}</div>}
                 {(agreeToDonate) && <span className="label">Donation:</span>}
              {(agreeToDonate) && <div className="amount">${restaurantDonationCents / 100}</div>}
              <span className="label">status:</span>
              <div className="status">{(paymentType === 'CARD') ? 'Prepaid' : 'Not Paid'}</div>

            </div>}

            {totalPrice && <div className="payment-info">
              {/* <div className="payment-type">
                {isCash && "Unpaid. Collect payment from customer."}
              </div> */}
              {(paymentType === 'CARD') && <Payment chargeResult={chargeResult} toDoRefundInCents={toDoRefundInCents} tips={tips}></Payment>}

            </div>}
          </div>

          <div className="actions pane">
            <OrderItemAdmin orderSnapshot={snapshot} />
          </div>

          <NotifyStatus
            notify_id={`${id}${r_id}`}
            mode="embeded"
            phone={phone}
          />

          <div className="other pane">

            <div style={{ textAlign: 'right' }}>
              <a href={`tel:${restaurantPhoneNumber}`}>
                {restaurantPhoneNumber}
              </a>
              <span>{"  "}</span>
              <a href={`https://oe6ad51p3k.execute-api.us-west-1.amazonaws.com/prod/pdf?order_id=${id}&r_id=${r_id}`}>
                Fax
              </a>
              <span>{"  "}</span>
              <a href={`https://z049ngmfo5.execute-api.us-west-1.amazonaws.com/public/receipts?order_id=${id}&r_id=${r_id}`}>
                Receipt
              </a>
              <span>{"  "}</span>
              {(process.env.NODE_ENV === 'development') && <a href={`https://console.firebase.google.com/u/2/project/afa-orders/database/afa-orders/data/~2Forders/${r_id}/${id}`}>
                firebase
              </a>}
            </div>
          </div>

        </div>)

}

const Schedule = ({ts, type, c_at, cartType, r_id}) => {
  let now = Date.now()
  if (ts === 'ASAP' || !ts) {
    if (type === 'product') { return null }
    let scheduledText = `${moment(c_at).fromNow()}`
    let exactTimeText = `${moment(c_at).calendar()}`
    if (r_id === 'charm') {
      scheduledText = `${moment(c_at).tz('America/Los_Angeles').fromNow()}`
      exactTimeText = `${moment(c_at).tz('America/Los_Angeles').calendar()}`
    }
    // console.log('minus:', (now + 60 * 60 * 1000) - c_at)
    return(
      <div className="schedule">
        <span className="scheduled-text">
          <span ><Icon iconSize={20} className='time-icon' icon="time"/>ASAP</span>
          <span> order created </span>
          {/* if within 60 minuts past creation time, display relative time, otherwise absolute time.  */}
          {(c_at < (now - 60 * 60 * 1000)) ?
            <span> {exactTimeText}</span> : <span>{scheduledText}</span>}
        </span>
      </div>
    )
  }
  ts = Number(ts)

  let warningClass = ""
  // for product item reminders, only display if due more than 20 minutes later
  if (type === 'product' && (ts - 20 * 60 * 1000) < now) { return null }
  // add warning color if order is more than 20 minutes away
  if (now < ts - 20 * 60 * 1000) { warningClass = "warning-text" }
  //
  let scheduledText = `${moment(ts).fromNow()}`
  if ((ts - 10 * 60 * 1000) < now && now < (ts + 10 * 60 * 1000)) { scheduledText = 'now' }
  // if (now > ts + )
  let exactTimeText = `${moment(ts).calendar()}`

  if (r_id === 'charm') {
    scheduledText = `${moment(ts).tz('America/Los_Angeles').fromNow()}`
    exactTimeText = `${moment(ts).tz('America/Los_Angeles').calendar()}`
  }


  return(
    <div className="schedule pane">
      <span className="scheduled-text">

        <span className={warningClass}>
          <Icon iconSize={20} icon="time"/>{cartType === 'DELIVERY' ? 'Deliver at:': 'Pickup at:'} {scheduledText}</span>
        {/* display exact time when due time has not yet come, or when due time is more than one hour old*/}
        {(ts > now && type !== 'product') && <span> ({exactTimeText})</span>}
        {(ts < now - 60 * 60 * 1000 && type !== 'product') && <span> ({exactTimeText})</span>}
      </span>
    </div>
  )
}

export default withRouter(OrderItem);
