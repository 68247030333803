import React, { useState, useEffect } from 'react'
import {Route, Switch, Link} from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import {Button, Checkbox, Card, Icon} from "@blueprintjs/core"
import values from 'object.values'

import useSyncOrders from './useSyncOrders'
import usePollSettings from './usePollSettings'
import usePollTasks from './usePollTasks'
// import useGetDeviceKey from './useGetDeviceKey'
// import useTracker from './useTracker'
// import useRemoteControl from './useRemoteControl'
import useInterval from './useInterval'
import './index.scss'

import FilterRadioGroup from '../FilterRadioGroup'
import mobilecheck from '../mobilecheck'
import IOSAudioEnabler from '../iOSAudioEnabler'
import localized from '../localized'

import InternetStatusBar from '../utils/InternetStatusBar'
import OrdersNavigation from './OrdersNavigation'
import OrderItem from './OrderItem'

import NewOrderAlert from './NewOrderAlert'
import ControlCenter from './ControlCenter'

import axios from 'axios'




function Orders(props) {
  const location = props.location
  const r_id = props.match.params.r_id
  // Sync Orders
  // time token triggers resync of database every day
  const timeToken = (new Date()).getDay()
  const [ordersSnapshot, newOrders, newOrdersDispatch] = useSyncOrders(r_id, timeToken)
  const settings = usePollSettings(r_id)
  // usePollTasks()
  const {restaurantPhone, enableOnOffSwitchAndWaitTime} = settings
  // console.log(settings)
  // const deviceKey = useGetDeviceKey()
  // useTracker(r_id, deviceKey)
  // useRemoteControl(r_id, deviceKey)
  useEffect(function () {
    axios.post('https://afa-debug.glitch.me/log', {
      r_id, action: "ORDER_SCREEN_LOAD"

    })
  }, [r_id])

  


  usePollTasks()
  

  // Shop Data
  // const [shopData, setShopData] = useState(null)

  const [_, forceUpdate] = useState()
  useInterval(function () {
    forceUpdate(Date.now())
  }, 60 * 1000)

  if (r_id === 'error') { throw new Error('test error triggered!') }


  window.setAvailablePrinters = function (printers) {
    
    axios.post('https://afa-debug.glitch.me/log', {
      r_id, action: "SET_AVAILABLE_PRINTERS"
    })
  }

  return(
    <div className="pos-foh-screen"> 
      {mobilecheck() ? <IOSAudioEnabler></IOSAudioEnabler> : null}

      <InternetStatusBar></InternetStatusBar>

 
      <div className="top-bar">
      {(
        (['ksthollywood','shayanexpress','myfishstopnoho','pablitostacosoaks','gourmet88','frontierwok', 'nersses','mexcocina','twindragon','chinesedelight','shyo', 'brite', 'lams', 'kst', 'kokorollburbank','kokorolllacanada','romancing','newdeal', 'twoguysfromitalypasadena','twoguysfromitalyglendale','themelt', 'cravesushi','cravecafe', 'pablitostacosburbank', 'pablitostacosnoho','lancers', 'hayats', 'shiso', 'sushidon', 'larryschilidog', 'cravestudiocity', 'coralcafe', 'kiin', 'omars', 'unclereds', 'losamigos', 'siri', 'chiba', 'inthemix', 'phoseason', 'tokyoya', 'beachwood', 'sidewalk', 'domenicos', 'test'].indexOf(r_id) !==  -1) 
        || 
        (enableOnOffSwitchAndWaitTime)
      )
        && 
      <ControlCenter shopId={r_id} />}

      {(values(newOrders).length > -1) && <NewOrderAlert
          rootURL={props.match.url}
          snapshots={newOrders}></NewOrderAlert>}

      </div>



      <OrdersNavigation
        rootURL={props.match.url}
        snapshot={ordersSnapshot}
        newOrders={newOrders}
      />

      <div className="right-screen">
      

        <TransitionGroup

        >
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames='order-item-transition'
          >
            <Switch>
              <Route path={`${props.match.url}/:order_id`} render={({match}) => {
                if (!ordersSnapshot) {return null}
                const order_id = match.params.order_id

                // set order to read
                if (newOrders[order_id]) {
                  newOrdersDispatch({type: 'remove', order: newOrders[order_id]})
                }

                return(<div className="foh__order-details">
                  <OrderItem
                    orderListURL={props.match.url}
                    settings={settings}
                    snapshot={ordersSnapshot.child(order_id)}/>
                </div>)
              }} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>


    </div>
  )
}




export default Orders;


// function mapStyles(styles) {
//   return {
//     opacity: styles.opacity,
//     transform: `scale(${styles.scale})`,
//   };
// }

// // wrap the `spring` helper to use a bouncy config
// function bounce(val) {
//   return spring(val, {
//     stiffness: 330,
//     damping: 22,
//   });
// }

// // child matches will...
// const bounceTransition = {
//   // start in a transparent, upscaled state
//   atEnter: {
//     opacity: 0,
//     scale: 1.2,
//   },
//   // leave in a transparent, downscaled state
//   atLeave: {
//     opacity: bounce(0),
//     scale: bounce(0.8),
//   },
//   // and rest at an opaque, normally-scaled state
//   atActive: {
//     opacity: bounce(1),
//     scale: bounce(1),
//   },
// };









